import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import COLORS from 'constants/colors';

export const Container = styled.div`
  position: relative;
  align-self: center;

  &:last-child {
    justify-self: flex-end;
  }

  .gatsby-image-wrapper {
    width: 50vw;
    height: 50vw;
    border-radius: 50%;

    ${MEDIA.MIN_TABLET`
      max-width: 180px;
      max-height: 180px;
    `};
  }
`;

export const Round = styled.div`
  border-radius: 50%;
  width: 50vw;
  height: 50vw;
  background: ${({ styleColor }) => COLORS[styleColor]};
  opacity: ${({ isGraph }) => isGraph && 0.65};
  position: absolute;
  top: 100px;
  left: ${({ isGraph }) => (isGraph ? '100px' : '-100px')};
  z-index: ${({ isGraph }) => (isGraph ? 2 : -1)};

  ${MEDIA.MIN_TABLET`
    max-width: 180px;
    max-height: 180px;
  `};

  ${Container} ~ ${Container} & {
    top: -100px;
    left: -100px;
  }
`;
