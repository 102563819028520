import React from 'react';
import PropTypes from 'prop-types';
import { Container, Round } from './profile-image.css';

const ProfileImage = ({ children, color, isGraph = false }) => (
  <Container className={'profileImage'}>
    {children}

    <Round styleColor={color} isGraph={isGraph} />
  </Container>
);

ProfileImage.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isGraph: PropTypes.bool,
  color: PropTypes.oneOf(['green', 'darkGreen', 'blue', 'pink', 'white']),
};

export default ProfileImage;
